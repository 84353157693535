import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Table } from 'reactstrap';
import { useSpotify } from 'spotify';
import SongArtists from './SongArtists';
import SongName from './SongName';

function wrap(content) {
  return <div style={{ height: 65, overflowY: 'hidden' }}>
    {content}
  </div>;
}

function VotesTable({ votes, add, remove, setErrorMessage }) {
  const [songs, setSongs] = useState({});
  const [positions, setPositions] = useState({});
  const spotify = useSpotify(setErrorMessage);

  useEffect(() => {
    if (Object.keys(votes).length === 0) return;
    const setSongsAsync = async () => Object.fromEntries(await Promise.all(
      Object.keys(votes).map(async id => [id, await spotify.fetchSongById(id)])
    ));
    setSongsAsync().then(newSongs => setSongs(newSongs));
    const newPositions = Object.fromEntries(Object.entries(votes).map(([id, count], i) => [id, Object.values(votes).filter((c, j) => c > count || (c === count && j < i)).length]));
    setPositions(newPositions);
  }, [votes, spotify]);

  return (
    <Table>
      <tbody>
        {votes && Object.entries(votes).sort(([a, _], [b, __]) => a > b ? 1 : -1).map(([id, count], i) => {
          const song = songs[id];
          return (
            <tr key={id} style={{ transform: `translateY(${(positions[id] - i) * 100}%)` }}>
              <td width='100%'>
                {wrap(
                  <>
                    <span><SongName song={song} /><br /></span>
                    <span className='small'><SongArtists song={song} /></span>
                  </>
                )}
              </td>
              <td className='align-middle text-nowrap'>
                {wrap(count)}
              </td>
              <td className='align-middle text-nowrap'>
                {wrap(
                  <ButtonGroup>
                    <Button title='Add vote' size='sm' color='light' onClick={() => add(id)} style={{ width: 30 }}>+</Button>
                    {count === 0
                      ? <Button title='Remove vote' size='sm' color='warning' onClick={() => remove(id)} style={{ width: 30 }}>&#8856;</Button>
                      : <Button title='Remove vote' size='sm' color='light' onClick={() => remove(id)} style={{ width: 30 }}>&ndash;</Button>
                    }
                  </ButtonGroup>
                )}
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  );
}

export default VotesTable;
