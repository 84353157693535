import React from 'react';

function MusicLeaguePlaylist({ playlist }) {
  return (
    <>
      From <a href={playlist.external_urls.spotify}>{playlist.name}</a>
    </>
  );
}

export default MusicLeaguePlaylist;
