import React from 'react';

function SongArtists({ song }) {
  return (
    <>
      {song?.artists
        .map(a => <a key={a.id} href={a.external_urls.spotify}>{a.name}</a>)
        .reduce((prev, curr) => (prev ? [prev, ', ', curr] : curr), null)}
    </>
  );
}

export default SongArtists;
