import Current from 'components/Current.jsx';
import VotesTable from 'components/VotesTable.jsx';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Row } from 'reactstrap';

function Home() {
  const [votes, setVotes] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const setAndStoreVotes = (newVotes) => {
    setVotes(newVotes);
    localStorage.setItem('votes', JSON.stringify(newVotes));
  }

  const addVote = async (id) => {
    console.log('Voting');
    const count = (votes[id] ?? 0) + 1;
    const entriesBefore = Object.entries(votes).filter(([_, c]) => c >= count);
    const entriesAfter = Object.entries(votes).filter(([id_, c]) => id !== id_ && c < count);
    const newVotes = Object.fromEntries([...entriesBefore, [id, count], ...entriesAfter]);
    setAndStoreVotes(newVotes);
  };

  const addSongs = async (ids) => {
    console.log('Adding songs');
    const newVotes = {...votes};
    for(const id of ids) {
      if (votes[id] === undefined) newVotes[id] = 0;
    }
    setAndStoreVotes(newVotes);
  };

  const removeVote = async (id) => {
    console.log('Removing vote');
    if (!(id in votes)) return;
    const count = votes[id] - 1;
    const entriesBefore = Object.entries(votes).filter(([id_, c]) => id !== id_ && c > count);
    const entriesAfter = Object.entries(votes).filter(([_, c]) => c <= count);
    const newVotes = Object.fromEntries([...entriesBefore, [id, count], ...entriesAfter]);
    if (count < 0) delete newVotes[id];
    setAndStoreVotes(newVotes);
  };

  const resetVotes = async () => {
    console.log('Resetting votes');
    setAndStoreVotes({});
  };

  useEffect(() => {
    const storedVotes = localStorage.getItem('votes');
    if (storedVotes) setVotes(JSON.parse(storedVotes));
  }, []);

  return (
    <Row>
      <Col lg={6}>
        <Current addVote={addVote} addSongs={addSongs} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
      </Col>
      <Col lg={6}>
        <VotesTable votes={votes} add={addVote} remove={removeVote} setErrorMessage={setErrorMessage} />
        <ButtonGroup className='float-end'>
          <Button onClick={resetVotes} disabled={Object.keys(votes).length === 0} color='danger'>Reset all votes</Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
}

export default Home;
