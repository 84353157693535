import React, { useCallback, useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { Button, ButtonGroup, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle } from 'reactstrap';
import { useSpotify } from 'spotify';
import ErrorMessage from './ErrorMessage';
import SongArtists from './SongArtists';
import SongName from './SongName';
import MusicLeaguePlaylist from './MusicLeaguePlaylist';

function Current({ addVote, addSongs, errorMessage, setErrorMessage }) {
  const [currentSong, setCurrentSong] = useState({ id: null, name: 'No song', artists: [], external_urls: { spotify: null } });
  const [currentPlaylist, setCurrentPlaylist] = useState({ id: null, uri: null, owner: { id: null }, tracks: { items: [] } });
  const spotify = useSpotify(setErrorMessage);
  const isVisible = usePageVisibility();

  const resetCurrentPlaylist = () => {
    setCurrentPlaylist({ id: null, uri: null, owner: { id: null }, tracks: { items: [] } });
  };

  const updateCurrent = useCallback(async () => {
    const currentlyPlaying = await spotify.fetchCurrentlyPlaying();
    if (currentlyPlaying.error) return;
    setCurrentSong(currentlyPlaying.item);
    const newCurrentPlaylistId = currentlyPlaying.context.uri.replace(/^spotify:playlist:/, '');
    let newCurrentPlaylist = await spotify.fetchPlaylistById(newCurrentPlaylistId);
    setCurrentPlaylist(newCurrentPlaylist);
  }, [spotify]);

  const addVoteToCurrent = async () => {
    const currentlyPlaying = await spotify.fetchCurrentlyPlaying();
    if (currentlyPlaying.item.id !== currentSong.id) {
      setErrorMessage('Song has changed, no vote added!');
      setCurrentSong(currentlyPlaying.item);
      resetCurrentPlaylist();
      return;
    }
    addVote(currentSong.id);
    spotify.fetchSongById(currentSong.id);
  };

  const addSongsFromCurrentPlaylist = async () => {
    const currentlyPlaying = await spotify.fetchCurrentlyPlaying();
    if (currentlyPlaying.context.type !== 'playlist' || currentlyPlaying.context.uri !== currentPlaylist.uri) {
      setErrorMessage('Playlist has changed, no songs added!');
      setCurrentSong(currentlyPlaying.item);
      resetCurrentPlaylist();
      return;
    }
    addSongs(currentPlaylist.tracks.items.map(({ track }) => track.id).sort((a, b) => a > b ? 1 : -1));
  };

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    updateCurrent();
    const interval = setInterval(updateCurrent, 10000);
    return () => clearInterval(interval);
  }, [updateCurrent, isVisible]);

  return (
    <>
      <Card className='mt-1 mb-2'>
        <CardHeader>
          <ButtonGroup className='float-end'>
            <Button title='Refresh current' size='sm' color='light' onClick={updateCurrent} className='rounded-circle'>&#8635;</Button>
          </ButtonGroup>
          <div className='float-end small mx-4 mt-1'><ErrorMessage reset={() => setErrorMessage('')}>{errorMessage}</ErrorMessage></div>
          <CardText style={{ marginBottom: '.2rem' }}>Currently playing</CardText>
        </CardHeader>
        <CardBody>
          <ButtonGroup className='float-end'>
            <Button onClick={addVoteToCurrent} disabled={!currentSong.id} color='primary'>Vote for this</Button>
          </ButtonGroup>
          <CardTitle tag='h2'><SongName song={currentSong} /></CardTitle>
          <CardText tag='h4'><SongArtists song={currentSong} /></CardText>
        </CardBody>
        {currentPlaylist.id !== null
          &&
          <CardFooter>
            <ButtonGroup className='float-end'><Button size='sm' onClick={addSongsFromCurrentPlaylist}>Add all songs</Button></ButtonGroup>
            <CardText tag='h5'><MusicLeaguePlaylist playlist={currentPlaylist} /></CardText>
          </CardFooter>
        }
      </Card>
    </>
  );
}

export default Current;
