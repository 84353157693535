import Policy from 'pages/Policy';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import Callback from './pages/Callback';
import Home from './pages/Home';
import { callback_path, home_path, policy_path } from './routes';

const App = () => {
  useEffect(() => {
    document.title = 'MüsliVote';
  }, []);

  return (
    <Router>
      <Navbar
        className='mb-1 pt-3 pb-2 mb-lg-3 pt-lg-5 pb-lg-4'
        color='dark'
        dark
      >
        <Container>
          <div className='float-end'>
            <Link to={home_path} className='btn btn-dark btn-sm'>Home</Link>
            <Link to={policy_path} className='btn btn-dark btn-sm'>Privacy policy</Link>
          </div>
          <NavbarBrand href={home_path}>
            <img
              alt='logo'
              src='/logo512.png'
              style={{
                height: 40,
                width: 40,
                marginRight: '1rem',
              }}
            />
            MüsliVote
          </NavbarBrand>
        </Container>
      </Navbar>
      <Container className='mb-3'>
        <Routes>
          <Route path={home_path} element={<Home />} />
          <Route path={callback_path} element={<Callback />} />
          <Route path={policy_path} element={<Policy />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
