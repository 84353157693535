import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { home_path } from '../routes';
import { Spinner } from 'reactstrap';


function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const authCode = params.get('code');
    if (!authCode) return navigate(home_path);
    console.log('Setting auth code');
    localStorage.setItem('spotify/authCode', authCode);
    return navigate(home_path);
  }, [navigate]);

  return (
    <Spinner animation='border' />
  );
}

export default Home;
