import { Buffer } from 'buffer';
import { useMemo } from 'react';
import { callback_path } from 'routes';

const client_credentials = process.env['REACT_APP_SPOTIFY_CLIENT_CREDENTIALS'];
const client_id = client_credentials.split(':')[0];

function formatParams(params) {
  return Object.entries(params).map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&');
}

class SpotifyClient {
  constructor(setErrorMessage) {
    if (setErrorMessage) this._setErrorMessage = setErrorMessage.bind(this);
  }

  async fetchCurrentlyPlaying() {
    const json = await this._callV1Api('me/player/currently-playing');
    if (!json.item) {
      this._setErrorMessage('Error fetching currently playing: No song playing');
      return { error: 'No song playing' };
    }
    const songs = this._readSongsFromLocalStorage();
    if (json.item.id || !(json.item.id in songs)) {
      songs[json.item.id] = json.item;
      this._writeSongsToLocalStorage(songs);
    }
    return json;
  }

  async fetchSongById(id) {
    const songs = this._readSongsFromLocalStorage();
    if (!(id in songs)) {
      const json = await this._callV1Api(`tracks/${id}`);
      if (!json) {
        this._setErrorMessage('Error fetching song by id: Cannot find song');
        return { error: 'Cannot find song' };
      }
      if (json.error) {
        this._setErrorMessage(`Error fetching song by id: ${json.error}`);
        return { error: json.error };
      }
      songs[id] = json;
      this._writeSongsToLocalStorage(songs);
    }
    return songs[id];
  }

  async fetchPlaylistById(id) {
    const playlists = this._readPlaylistsFromLocalStorage();
    if (!(id in playlists)) {
      const json = await this._callV1Api(`playlists/${id}`);
      if (!json) {
        this._setErrorMessage('Error fetching playlist by id: Cannot find playlist');
        return { error: 'Cannot find playlist' };
      }
      if (json.error) {
        this._setErrorMessage(`Error fetching playlist by id: ${json.error}`);
        return { error: json.error };
      }
      playlists[id] = json;
      this._writePlaylistsToLocalStorage(playlists);
    }
    return playlists[id];
  }

  _setErrorMessage(errorMessage) {
  };

  async _getAccessToken() {
    const expiresAt = parseInt(localStorage.getItem('spotify/expiresAt') || '0');
    if (!expiresAt || (new Date()).getTime() >= expiresAt) {
      localStorage.removeItem('spotify/accessToken');
      localStorage.removeItem('spotify/expiresAt');
      if (localStorage.getItem('spotify/refreshToken')) await this._requestRefreshedToken();
      else if (localStorage.getItem('spotify/authCode')) await this._requestNewToken();
      else await this._initAuth();
    }
    return localStorage.getItem('spotify/accessToken');
  }

  async _initAuth() {
    const authorizeUrl = 'https://accounts.spotify.com/authorize?' +
      'response_type=code&' +
      'client_id=' + client_id + '&' +
      'redirect_uri=' + encodeURIComponent(window.location.origin + callback_path) + '&' +
      'scope=user-read-currently-playing';
    localStorage.removeItem('spotify/accessToken');
    localStorage.removeItem('spotify/expiresAt');
    localStorage.removeItem('spotify/refreshToken');
    // @ts-ignore
    window.location = authorizeUrl;
  }

  async _requestNewToken() {
    const json = await this._callAccountsApi('token', {
      'grant_type': 'authorization_code',
      'code': localStorage.getItem('spotify/authCode'),
      'redirect_uri': window.location.origin + callback_path
    });
    if ('error' in json) {
      this._setErrorMessage(`Error requesting token: ${json.error}`);
      return;
    }
    localStorage.removeItem('spotify/authCode');
    localStorage.setItem('spotify/accessToken', json.access_token);
    localStorage.setItem('spotify/expiresAt', (new Date()).getTime() + (json.expires_in - 5) * 1000 + '');
    localStorage.setItem('spotify/refreshToken', json.refresh_token);
  }

  async _requestRefreshedToken() {
    const json = await this._callAccountsApi('token', {
      'grant_type': 'refresh_token',
      'refresh_token': localStorage.getItem('spotify/refreshToken')
    });
    if ('error' in json) {
      this._setErrorMessage(`Error refreshing token: ${json.error}`);
      return;
    }
    localStorage.setItem('spotify/accessToken', json.access_token);
    localStorage.setItem('spotify/expiresAt', (new Date()).getTime() + (json.expires_in - 5) * 1000 + '');
  }

  async _callAccountsApi(endpoint, params) {
    try {
      const response = await fetch(`https://accounts.spotify.com/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic ' + Buffer.from(client_credentials).toString('base64')
        },
        body: formatParams(params),
      });
      return response.json();
    } catch (err) {
      return {
        error: err.message
      };
    }
  }

  _readSongsFromLocalStorage() {
    return JSON.parse(localStorage.getItem('spotify/songs')) || {};
  }

  _writeSongsToLocalStorage(songs) {
    localStorage.setItem('spotify/songs', JSON.stringify(songs));
  }

  _readPlaylistsFromLocalStorage() {
    return JSON.parse(localStorage.getItem('spotify/playlists')) || {};
  }

  _writePlaylistsToLocalStorage(songs) {
    localStorage.setItem('spotify/playlists', JSON.stringify(songs));
  }

  async _callV1Api(endpoint, params) {
    try {
      const accessToken = await this._getAccessToken();
      const response = await fetch(`https://api.spotify.com/v1/${endpoint}${params ? '?' + formatParams(params) : ''}`, {
        headers: {
          'Authorization': 'Bearer ' + accessToken
        }
      });
      if (response.status === 204) return {};
      else return response.json();
    } catch (err) {
      return {
        error: err.message
      };
    }
  }
}

export function useSpotify(setErrorMessage) {
  return useMemo(() => new SpotifyClient(setErrorMessage), [setErrorMessage]);
}
