import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';

function ErrorMessage({ children, reset }) {
  useEffect(() => {
    if (!children) return;
    const timeout = setTimeout(reset, 3200);
    return () => clearTimeout(timeout);
  }, [children, reset]);

  return children && (
    <Alert key={JSON.stringify(children)} className='alert-warning' style={{
      animation: 'error-fade 3s',
      opacity: 0
    }}>
      {children}
    </Alert>
  );
}

export default ErrorMessage;
