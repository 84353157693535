import React from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

function Home() {
  return (
    <Card>
      <CardBody>
        <CardTitle tag='h1'>Privacy Policy</CardTitle>
        <CardText>
          The only data ever collected is your currently playing song, and all data is only ever stored in your browser.
        </CardText>
      </CardBody>
    </Card>
  );
}

export default Home;
